import React from 'react'

const Card = ({project}) => {
  if(!project) return
  return (
    <div className="w-[350px] xl:w-[500px] bg-white overflow-hidden rounded-lg border shadow-2xl border-gray-200 shadow-mdS">
      <div className='bg-white p-8'>
      <img className="object-contain h-[150px] w-[200px] mx-auto fill-black" src={project && project.logo} alt="logo" />
      </div>
    <div className="px-5 py-8 bg-slate-800 min-h-[280px] h-full flex flex-col justify-evenly items-start gap-3 mt-3">
        <div>
        {project && project.status &&
        <span class="bg-yellow-100 text-yellow-800 text-sm font-semibold mr-2 px-2.5 py-0.5 rounded">{project && project.status}</span>
        }
        <span className="bg-indigo-100 text-indigo-800 text-sm font-medium mr-2 px-2.5 py-0.5 rounded">{project && project.development}</span>
        <span class="bg-green-100 text-green-800 text-sm font-medium mr-2 px-2.5 py-0.5 rounded">Design: {project && project.design}</span>
        </div>
        <h5 className="text-2xl font-bold tracking-tight text-white ">{project && project.name}</h5>
        {
        project && project.created_date &&
        <p className="font-normal text-white">Deployment Date: {project && project.created_date}</p>
          }
        {project && project.update_dates.length !== 0 && 
        <p className="font-normal text-white">Update Dates: 
        {project.update_dates.map(date =><span className='px-1'>{date},</span>
        )}</p>
        }
        <div className='flex gap-3'>
        <a href={project && project.url} target="_blank" rel="noreferrer" className="inline-flex items-center py-2 px-3 text-sm font-medium text-center text-white bg-blue-700 rounded-lg hover:bg-blue-800 focus:ring-4 focus:outline-none focus:ring-blue-300">
           Website
            <svg aria-hidden="true" className="ml-2 -mr-1 w-4 h-4" fill="currentColor" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg"><path fillRule="evenodd" d="M10.293 3.293a1 1 0 011.414 0l6 6a1 1 0 010 1.414l-6 6a1 1 0 01-1.414-1.414L14.586 11H3a1 1 0 110-2h11.586l-4.293-4.293a1 1 0 010-1.414z" clipRule="evenodd"></path></svg>
        </a>
        {
        project && project.mockup &&
        <a href={project && project.mockup}  target="_blank" rel="noreferrer" className="inline-flex items-center py-2 px-3 text-sm font-medium text-center text-white bg-blue-700 rounded-lg hover:bg-blue-800 focus:ring-4 focus:outline-none focus:ring-blue-300">
           Mockup
            <svg aria-hidden="true" className="ml-2 -mr-1 w-4 h-4" fill="currentColor" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg"><path fillRule="evenodd" d="M10.293 3.293a1 1 0 011.414 0l6 6a1 1 0 010 1.414l-6 6a1 1 0 01-1.414-1.414L14.586 11H3a1 1 0 110-2h11.586l-4.293-4.293a1 1 0 010-1.414z" clipRule="evenodd"></path></svg>
        </a>
        }
        {
        project && project.old_img &&
        <a href={project && project.old_img} target="_blank" rel="noreferrer" className="inline-flex items-center py-2 px-3 text-sm font-medium text-center text-white bg-blue-700 rounded-lg hover:bg-blue-800 focus:ring-4 focus:outline-none focus:ring-blue-300">
           Previous website capture
            <svg aria-hidden="true" className="ml-2 -mr-1 w-4 h-4" fill="currentColor" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg"><path fillRule="evenodd" d="M10.293 3.293a1 1 0 011.414 0l6 6a1 1 0 010 1.414l-6 6a1 1 0 01-1.414-1.414L14.586 11H3a1 1 0 110-2h11.586l-4.293-4.293a1 1 0 010-1.414z" clipRule="evenodd"></path></svg>
        </a>
        }
        </div>
       
    </div>
</div>
  )
}

export default Card