import Card from './components/Card';
import Navigation from './components/Navigation';
import data from './data.json'

function App() {
  const projects = data
  return (
  <>
  <Navigation />
    <div className='w-full h-full bg-white flex justify-center overflow-hidden'>
    <div className="flex w-11/12 xl:w-10/12 flex-wrap justify-start items-center gap-10 mx-auto pt-10">
      {
       projects && projects.map(project => (
        <Card key={project.name} project={project} />)
        )
      }
      <Card />
    </div>
    </div>
    </>
  );
}

export default App;
